<template>
  <div>
    <div class="head">
      <div class="head-title">充值鸡</div>
    </div>
    <div class="middle1">
      <div class="middle1-title">一个专业的充值平台</div>
      <div class="middle1-msg">视频会员、Q币、话费，一应俱全</div>
      <div class="middle1-icon">
        <img src="../assets/recharge/about_icon1.png" alt="" />
        <img src="../assets/recharge/about_icon2.png" alt="" />
        <img src="../assets/recharge/about_icon3.png" alt="" />
      </div>
      <div class="middle1-text">
        小鸡充值是一款专为玩家定制的特价充值APP，充值产品包括话费，流量，油卡，音视频会员，<br />
        购物卡等一站式充值。用户可使用支付宝或小鸡充值APP钱包余额等<br />
        多种支付方式进行一键式快充，安全高效。
      </div>
    </div>
    <div class="middle2">
      <img src="../assets/recharge/about_img1.png" alt="" />
      <div class="middle2-box"></div>
    </div>
    <div v-if="$store.getters.getIsPhone" class="middle3">
      <div class="midddle3-title">发展历程</div>
      <div class="midddle3-text">从破壳到长大，每一步都算数</div>
      <div class="middle3-step">
        <div class="step-icon" @click="hanleStep('prev')">
          <img src="../assets/recharge/honor_prev.png" alt="" />
        </div>
        <div class="step-content">
          <div class="step-content_time">{{ step.time }}</div>
          <div class="step-conten_info">
            <div style="width: 90%">
              <div class="info-title" :class="{ t5: flag }" :style="{ marginLeft: left + 'px', opacity: op }">{{ step.title }}</div>
              <div class="info-msg" :class="{ t5: flag }" :style="{ marginLeft: left + 'px', opacity: op2 }">{{ step.msg }}</div>
            </div>
          </div>
        </div>
        <div class="step-icon" @click="hanleStep('next')">
          <img src="../assets/recharge/honor_next.png" alt="" />
        </div>
      </div>
      <div class="step-box">
        <div class="step-myline" :style="{ transform: `translateX(${tx + 800}px)` }"></div>
        <div class="step-line" v-for="(item, index) in steps" :key="item?.time" :style="{ transform: `translateX(${tx + 800}px)` }">
          <div class="step-count" :class="{ checkStep: index === stepKey }" @click="changeStep(index)"></div>
          <div class="step-text">{{ item.time }}</div>
        </div>
        <div class="step-myline" :style="{ transform: `translateX(${tx + 800}px)` }"></div>
      </div>
    </div>
    <div v-else class="phone-middle3">
      <div class="midddle3-title">发展历程</div>
      <div class="midddle3-text">从破壳到长大，每一步都算数</div>
      <div class="midddle3-tabs">
        <el-carousel trigger="click" :autoplay="false" height="200px" indicator-position="outside">
          <el-carousel-item v-for="item in steps" :key="item.time">
            <div class="tab-list">
              <div class="tab-list_time">{{ item.time }}</div>
              <div class="tab-list_title">{{ item.title }}</div>
              <div class="tab-list_msg">{{ item.msg }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { stepData } from "./step"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      step: { time: "", title: "", msg: "" },
      left: 100,
      op: 0,
      op2: 0,
      op3: 0,
      flag: true,
      stepKey: 0,
      steps: [],
      tx: 0,
    }
  },
  methods: {
    hanleStep(type) {
      if (type === "prev") {
        if (this.stepKey === 0) {
          this.tx = -280 * this.stepKey
          return
        }
        const _index1 = this.stepKey - 1
        this.tx = -280 * _index1
        this.stepKey = _index1
        this.step = stepData[this.stepKey]
      } else if (type === "next") {
        if (this.stepKey === stepData.length - 1) {
          return
        }
        const _index2 = this.stepKey + 1
        this.tx = -280 * _index2
        this.stepKey = _index2
        this.step = stepData[this.stepKey]
      }
      this.flag = false
      this.left = 100
      this.op = 0
      this.op2 = 0
      this.op3 = 0
      setTimeout(() => {
        this.flag = true
        this.left = 0
        this.op = 1
        this.op2 = 0.7
        this.op3 = 1
      }, 500)
    },
    changeStep(index) {
      this.tx = -280 * index
      this.stepKey = index
      this.step = stepData[index]
      this.flag = false
      this.left = 100
      this.op = 0
      this.op2 = 0
      this.op3 = 0
      setTimeout(() => {
        this.flag = true
        this.left = 0
        this.op = 1
        this.op2 = 0.7
        this.op3 = 1
      }, 500)
    },
  },
  mounted() {
    this.step = stepData[0]
    this.steps = stepData
    setTimeout(() => {
      this.left = 0
      this.op = 1
      this.op2 = 0.7
      this.op3 = 1
    }, 500)
  },
}
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 21vw;
  background: #00b0ec url(../assets/recharge/about_bg1.png) no-repeat center top;
  background-size: cover;
  color: #fff;
  padding-top: 6vw;
  box-sizing: border-box;
  .head-title {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    color: #fff;
  }
}
.middle1 {
  width: 100%;
  background: url(../assets/recharge/about_bg2.png) no-repeat center top #fff;
  padding-top: 5.2vw;
  .middle1-title {
    width: 100%;
    text-align: center;
    font-size: 5.2vw;
    color: #000;
  }
  .middle1-msg {
    width: 100%;
    text-align: center;
    font-size: 2.2vw;
    color: #545454;
    margin-top: 1.5vw;
  }
  .middle1-icon {
    width: 100%;
    padding: 5vw 0 2vw 0;
    display: flex;
    justify-content: center;
    img {
      width: 9vw;
      max-width: 100px;
      margin: 0 1vw;
    }
  }
  .middle1-text {
    text-align: center;
    line-height: 2vw;
    font-size: 1.2vw;
    opacity: 0.8;
  }
}
.middle2 {
  width: 100%;
  height: 48vw;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #f6f6f6;
  .middle2-box {
    width: 100%;
    height: 20vw;
    position: absolute;
    background: #fff;
    top: 0;
    z-index: 1;
  }
  img {
    position: absolute;
    z-index: 2;
    margin-top: 9vw;
    max-width: 90%;
  }
}
.middle3 {
  width: 100%;
  background-color: #f6f6f6;
  padding: 30px 0 50px 0;
  .midddle3-title {
    width: 100%;
    text-align: center;
    font-size: 58px;
  }
  .midddle3-text {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 26px;
    color: #545454;
  }
  .middle3-step {
    width: 1400px;
    margin: 90px auto;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    .step-icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: 18px;
      }
    }
    .step-icon:hover {
      box-shadow: 0 0 5px 5px #fff;
      cursor: pointer;
    }
    .step-content {
      flex: 1;
      display: flex;
      .step-content_time {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 84px;
        font-family: "微软雅黑";
        color: #000;
        opacity: 0.9;
        border-right: 1px solid #ccc;
      }
      .step-conten_info {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .info-title {
          width: 90%;
          line-height: 40px;
          font-size: 22px;
        }
        .info-msg {
          width: 90%;
          line-height: 20px;
          font-size: 12px;
        }
        .t5 {
          transition: all 0.5s;
        }
      }
    }
  }
  .step-box {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .step-myline {
      width: 80%;
      flex-shrink: 0;
      height: 3px;
      background-color: #e2e2e2;
      transition: all 0.5s;
    }
    .step-line {
      flex-shrink: 0;
      width: 280px;
      height: 3px;
      background-color: #e2e2e2;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.5s;
      .step-count {
        position: absolute;
        right: 0;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 50%;
        cursor: pointer;
      }
      .step-text {
        font-size: 28px;
        color: #a1a1a1;
        position: absolute;
        top: 20px;
        font-family: "宋体";
        right: -40px;
      }
      .checkStep {
        background-color: #00b0ec;
      }
    }
  }
}
.phone-middle3 {
  width: 100%;
  background-color: #f6f6f6;
  padding: 30px 0 50px 0;
  .midddle3-title {
    width: 100%;
    text-align: center;
    font-size: 7vw;
  }
  .midddle3-text {
    width: 100%;
    margin-top: 3vw;
    text-align: center;
    font-size: 4vw;
    color: #666;
  }
  .midddle3-tabs {
    width: 100%;
    margin-top: 8vw;
    .tab-list {
      width: 100%;
      padding: 0 14vw;
      box-sizing: border-box;
      .tab-list_time {
        width: 100%;
        text-align: center;
        font-size: 8vw;
      }
      .tab-list_title {
        width: 100%;
        margin-top: 4vw;
        font-size: 5vw;
      }
      .tab-list_msg {
        width: 100%;
        margin-top: 5vw;
        color: #666;
        font-size: 3.5vw;
      }
    }
  }
}
</style>
