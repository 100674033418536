/*
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 16:40:56
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-19 16:55:06
 */
export const stepData = [
    {
        time: "2024.01",
        title: "小鸡充值新版本上线",
        msg: "支持虚拟、实物文创类周边产品"
    },
    {
        time: "2020.05",
        title: "小鸡充值微信公众号、小程序开通便捷充值新入口",
        msg: "更有幸运大转盘、各期公众号活动优惠劵红包，优惠力度全网最低，更有贴心小鸡客服人员及时处理各类用户问题"
    },
    {
        time: "2017.03",
        title: "小鸡充值3.2.0版本 红包玩法惊喜上线",
        msg: "更新狂欢周，全民疯抢现金红包；商城上新，更有游戏范儿"
    },
    {
        time: "2016.12",
        title: "小鸡充值3.0版本正式上线",
        msg: "全新界面，清新简洁。增加了鸡蛋商城功能，用户专享极致充值体验！"
    },
    {
        time: "2016.09",
        title: "32游戏钱包正式改名为“小鸡充值”",
        msg: "全新起航 提供千余款游戏、各大视频会员、Q币、话费、流量等充值服务"
    },
    {
        time: "2016.06",
        title: "32游戏钱包独立的IP“游戏鸡”诞生",
        msg: "与全国各地近千余游戏厂商达成战略性合作，实现了手游、页游、网游、TV游一站式充值"
    },
    {
        time: "2016.04",
        title: "32游戏钱包升级至2.2版本",
        msg: "增加了积分兑换、活动商城、用户等级等多项功能，用户体验更完善"
    },
    {
        time: "2016.02",
        title: "32游戏钱包APP上线",
        msg: "游戏充值产品包括英雄联盟，魔兽世界、穿越火线、DOTA2、剑网等千余款游戏"
    }
]